<template>
  <el-dialog
    title="举报机构"
    :visible.sync="$visible"
    width="520px"
    @open="onOpen"
    @close="onCancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-position="left"
      label-width="120px">
      <el-form-item
        label="举报类型"
        prop="complaintType">
        <el-checkbox-group v-model="formData.complaintType">
          <div class="tw-flex tw-flex-col">
            <el-checkbox
              v-for="item in typeList"
              :key="item.value"
              :label="item.value">
              {{item.name}}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="举报说明"
        prop="content">
        <el-input
          v-model="formData.content"
          type="textarea" maxlength="300" show-word-limit
          :rows="3">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        size="small"
        @click="onCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        :loading="btnLoading"
        @click="onConfirm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgId: String
  },
  data () {
    return {
      typeList: [],
      formData: {
        complaintType: [],
        content: ''
      },
      rules: {
        complaintType: [
          { required: true, message: '请选择举报类型', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写举报说明', trigger: 'blur' }
        ]
      },
      btnLoading: false
    }
  },
  computed: {
    $visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onOpen () {
      api.getReportTypeList().then(res => {
        if (res.data.code === 0) {
          this.typeList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onCancel () {
      this.$visible = false
      this.$refs.form.resetFields()
    },
    onConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btnLoading = true
          api.createReport({
            organizationId: this.orgId,
            ...this.formData
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('举报成功')
              this.$visible = false
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
