<!--
  机构详情
    + 在资源详情中，点击服务机构的名字可进入机构主页
-->
<template>
  <div class="org_detail" v-loading="loading">
    <div class="detail_left">
      <!-- 机构详情 -->
      <div class="org_info module_box tw-mb-20px">
        <div class="module_box_header">
          <p>机构详情</p>
        </div>
        <div class="module_box_main">
          <div class="main_header">
            <div class="header_left">
              <el-image
                v-if="detail.logoUrl"
                style="width: 40px; height: 40px;margin-right:20px;"
                :src="detail.logoUrl"
                fit="cover">
              </el-image>
              <el-image
                v-else
                style="width: 40px; height: 40px;margin-right:20px;"
                :src="require('../../assets/images/default_avatar.png')"
                fit="cover">
              </el-image>
              <h1>{{ detail.name }}</h1>
            </div>
            <div class="tw-flex tw-items-center">
              <!-- 当前机构才显示此按钮 -->
              <el-button
                v-if="canEditOrg"
                type="primary"
                size="small"
                @click="editOrg">
                <i class="iconfont iconbianji tw-mr-10px"></i>修改机构信息
              </el-button>
              <div class="report" @click="onReport">
                <i class="iconfont iconjinggao"></i>
                <p>举报</p>
              </div>
            </div>
          </div>
          <div class="main_content">
            <div class="text_box">
              {{ detail.description }}
            </div>
            <div class="count_box">
              <div class="count_item">
                <h1 class="tw-text-primary">
                  {{ detail.avgScore }}
                </h1>
                <p>信用评价</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务团队 -->
      <div class="team_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>金牌服务团队</p>
        </div>
        <div class="module_box_main">
          <template v-if="teamList.length > 0">
            <i class="my-arrow-prev el-icon-arrow-left"></i>
            <swiper
              class="swiper_box"
              :options="swiperOptions">
              <swiper-slide
                class="swiper_slide_box"
                v-for="(item) in teamList"
                :key="item.id">
                <div class="team_item">
                  <div class="avatar_box">
                    <el-image
                      style="width:80px;height:100px;"
                      :src="item.avatarUrl"
                      fit="cover">
                    </el-image>
                  </div>
                  <div class="info_box">
                    <div class="name_box">
                      <h1>{{ item.name }}</h1>
                    </div>
                    <div class="des_box text_elip elip_multiple text_elip_line_3">
                      <p :title="item.des">{{ item.description }}</p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <i class="my-arrow-next el-icon-arrow-right"></i>
          </template>
          <p v-else class="tw-text-secondary-text tw-text-center">暂未添加团队列表</p>
        </div>
      </div>
      <!-- 合作商列表 -->
      <div class="company_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>合作服务供应商</p>
        </div>
        <div class="module_box_main">
          <template v-if="companyList.length > 0">
            <i class="my-com-arrow-prev el-icon-arrow-left"></i>
            <swiper
              class="swiper_box"
              :options="comSwiperOptions">
              <swiper-slide
                class="swiper_slide_box"
                v-for="(item) in companyList"
                :key="item.id">
                <div class="company_item">
                  <div class="item_header">
                    <el-image
                      style="width:40px;height:40px;"
                      :src="item.avatarUrl"
                      fit="cover">
                    </el-image>
                    <p class="text_elip">{{ item.name }}</p>
                  </div>
                  <div class="item_content">
                    <p class="text_elip elip_multiple text_elip_line_2" :title="item.description">{{ item.description }}</p>
                  </div>
                  <div class="item_footer">
                    <p>主营业务：{{ item.business }}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <i class="my-com-arrow-next el-icon-arrow-right"></i>
          </template>
          <p v-else class="tw-text-secondary-text tw-text-center">暂未添加供应商列表</p>
        </div>
      </div>
      <!-- 机构服务 -->
      <div class="module_box tw-mb-20px">
        <div class="module_box_header">
          <p>机构服务</p>
        </div>
        <div class="module_box_main">
          <service-list :list="orgServiceList"></service-list>
        </div>
      </div>
    </div>
    <!-- 服务记录 （应该就是订单列表） -->
    <div class="detail_right">
      <div class="recommend_list module_box tw-mb-20px">
        <div class="module_box_header">
          <p>服务记录</p>
        </div>
        <div class="module_box_main">
          <el-table
            class="my-el-table-border-dashed"
            :data="orgLogs"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="serviceName"
              label="服务/资源名称">
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="交易时间"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.createdAt | dateFormat }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 举报窗口 -->
    <report-dialog
      :visible.sync="reportDialogVisible"
      :orgId="orgId">
    </report-dialog>
  </div>
</template>

<script>
import serviceList from './components/service-list'
import * as api from '@/api'
import ReportDialog from '../resource-detail/service/report-dialog'

export default {
  data () {
    return {
      // 页面loading
      loading: false,
      // 机构详情
      detail: {},
      // 团队列表
      teamList: [],
      // 供应商列表
      companyList: [],
      // 机构服务列表
      orgServiceList: [],
      // 机构服务记录列表
      orgLogs: [],
      reportDialogVisible: false
    }
  },
  components: {
    serviceList,
    ReportDialog
  },
  computed: {
    // 机构id
    orgId () {
      return this.$route.params.id
    },
    // 团队swiper
    swiperOptions () {
      return {
        // autoplay: true,
        // 在slide之间设置距离
        spaceBetween: 60,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.my-arrow-next',
          prevEl: '.my-arrow-prev'
        }
      }
    },
    // 合作商swiper
    comSwiperOptions () {
      return {
        // autoplay: true,
        // 在slide之间设置距离
        spaceBetween: 60,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.my-com-arrow-next',
          prevEl: '.my-com-arrow-prev'
        }
      }
    },
    /**
     * 是否可以编辑机构的信息
     *  + 非普通用户
     *  + 当前机构为本人机构
     *  + 且本人为此机构的法人
     */
    canEditOrg () {
      return this.userInfo.organization && this.userInfo.organization.id === this.orgId && this.userInfo.organizationRole === 1
    }
  },
  created () {
    this.getOrgDetail()
    this.getOrgTeamList()
    this.getOrgProviderList()
    this.getOrgServiceList()
    this.getOrgServiceLogs()
  },
  methods: {
    // 获取服务详情
    getOrgDetail () {
      this.loading = true
      api.getOrgRzAuditDetail(this.orgId).then(res => {
        if (res.data.code === 0) {
          console.log('机构详情', res.data.data)
          this.detail = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取服务团队列表
    getOrgTeamList () {
      this.loading = true
      api.getOrgTeamList(this.orgId).then(res => {
        if (res.data.code === 0) {
          console.log('服务团队列表', res.data.data)
          this.teamList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取服务提供商列表
    getOrgProviderList () {
      this.loading = true
      api.getOrgProviderList(this.orgId).then(res => {
        if (res.data.code === 0) {
          console.log('服务提供商列表', res.data.data)
          this.companyList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取指定机构服务列表
    getOrgServiceList () {
      this.loading = true
      api.getOrgServiceList(this.orgId, {
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.data.code === 0) {
          console.log('机构服务列表', res.data.data)
          this.orgServiceList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取机构服务记录列表
    getOrgServiceLogs () {
      this.loading = true
      api.getOrgServiceLogs(this.orgId, {
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        if (res.data.code === 0) {
          console.log('机构服务记录列表', res.data.data)
          this.orgLogs = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 编辑机构信息
    editOrg () {
      window.open(`/org-edit/${this.orgId}`, '_blank')
    },
    // 举报
    onReport () {
      this.reportDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .org_detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .detail_left {
      width: calc(100% - 450px);
      margin-right: 20px;
      .org_info {
        .module_box_main {
          padding-bottom: 30px;
          .main_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 67px;
            border-bottom: 1px solid #DCDFE6;
            .header_left {
              display: flex;
              align-items: center;
              & > h1 {
                font-size: 26px;
                font-weight: bold;
                color: #000000;
                margin-right: 16px;
              }
            }
            .report {
              width: 60px;
              height: 24px;
              padding: 0 10px 0 8px;
              border-radius: 2px;
              @apply tw-flex tw-items-center tw-justify-between tw-ml-10px;
              @apply tw-text-secondary-text tw-text-12px;
              & > i {
                font-size: 12px;
              }
              &:hover {
                @apply tw-cursor-pointer tw-bg-danger tw-bg-opacity-10 tw-text-danger;
              }
            }
          }
          .main_content {
            display: flex;
            padding-top: 20px;
            .text_box {
              flex: 1;
            }
            .count_box {
              flex: 0 0 130px;
              position: relative;
              display: flex;
              height: 70px;
              background-color: #F7F8F9;
              border-radius: 4px;
              margin-left: 74px;
              .count_item {
                flex: 1;
                text-align: center;
                padding: 10px 0;
                & > h1 {
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 30px;
                }
                & > p {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                  color: #303133;
                }
              }
            }
          }
        }
      }

      .team_list {
        .module_box_main {
          position: relative;
          width: 100%;
          padding: 0 40px 30px 40px;
          --swiper-navigation-color:  #3473E6;/* 单独设置按钮颜色 */
          --swiper-navigation-size: 30px;/* 设置按钮大小 */
          & > i {
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            font-size: 35px;
            font-weight: bold;
            cursor: pointer;
            color: #3473E6;
            &.el-icon-arrow-right {
              right: 0;
            }
            &.el-icon-arrow-left {
              left: 0;
            }
            &.swiper-button-disabled {
              opacity: 0.3;
            }
          }
          .swiper_box {
            .swiper_slide_box {
              width: 330px;
              .team_item {
                display: flex;
                width: 330px;
                .avatar_box {
                  width: 90px;
                  min-width: 90px;
                  height: 110px;
                  margin-right: 20px;
                  padding: 10px 0 0 10px;
                  background: url('../../assets/images/detail/sanjiao.png') top no-repeat;
                }
                .info_box {
                  width: 100%;
                  .name_box {
                    width: 100%;
                    border-bottom: 1px dashed #DCDFE6;
                    height: 40px;
                    & > h1 {
                      font-size: 16px;
                      font-weight: bold;
                      line-height: 40px;
                      color: #3473E6;
                    }
                  }
                  .des_box {
                    padding-top: 10px;
                    & > p {
                      font-size: 12px;
                      font-weight: 500;
                      line-height: 20px;
                      color: #303133;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .company_list {
        .module_box_main {
          position: relative;
          width: 100%;
          padding: 0 40px 30px 40px;
          --swiper-navigation-color:  #3473E6;/* 单独设置按钮颜色 */
          --swiper-navigation-size: 30px;/* 设置按钮大小 */
          & > i {
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            font-size: 35px;
            font-weight: bold;
            cursor: pointer;
            color: #3473E6;
            &.el-icon-arrow-right {
              right: 0;
            }
            &.el-icon-arrow-left {
              left: 0;
            }
            &.swiper-button-disabled {
              opacity: 0.3;
            }
          }
          .swiper_box {
            .swiper_slide_box {
              width: 220px;
              .company_item {
                width: 220px;
                .item_header {
                  display: flex;
                  align-items: center;
                  height: 40px;
                  & > p {
                    margin-left: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #000000;
                  }
                }
                .item_content {
                  padding: 10px 0;
                  min-height: 52px;
                  & > p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #606266;
                  }
                }
                .item_footer {
                  & > p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #303133;
                  }
                }
              }
            }
          }
        }
      }
    }
    .detail_right {
      flex: 0 0 450px;
      .recommend_list {
        .module_box_main {
          padding-bottom: 20px;
          .recommend_item {
            padding-bottom: 10px;
            border-bottom: 1px dashed #DCDFE6;
            .item_header {
              display: flex;
              height: 50px;
              align-items: center;
              justify-content: space-between;
              & > h1 {
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                color: #606266;
                padding-right: 20px;
              }
              & > p {
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                color: #909399;
              }
            }
            .item_footer {
              display: flex;
              align-items: center;
              height: 30px;
              & > p {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                color: #909399;
                margin-left: 10px;
              }
            }
            &+.recommend_item {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .module_box {
      width: 100%;
      background-color: #fff;
      padding: 0 20px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      overflow: hidden;
      .module_box_header {
        display: flex;
        align-items: center;
        height: 50px;
        margin-bottom: 10px;
        &::before {
          content: "";
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
          margin-right: 10px;
        }
        & > p {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #31394C;
        }
      }
      .module_box_main {
        padding: 0 10px;
      }
      .module_box_footer {
        padding: 0 10px;
      }
    }
  }
</style>
